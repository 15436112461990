<template>
  <div class="contractWaybillPage">
    <div class="facilityBox">
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$router.push('/waybill/sceneWayBill')
  }
}
</script>

<style>
</style>